<template>
  <div style="width: 97%">
    <div>
      <label>{{ $t("emploi.listeEnseignants") }}</label>
      <a-select
        id="selectClass"
        class="mb-3"
        show-search
        :placeholder="$t('emploi.listeEnseignants')"
        option-filter-prop="children"
        :filter-option="filterOption"
        style="width: 400px; margin-left: 15px"
        @change="
          (val) => {
            selectTeacher(val);
          }
        "
      >
        <a-select-option
          v-for="teacher in teachers"
          :key="teacher._id"
          :value="teacher._id"
        >
          {{ teacher.employee.firstName + " " + teacher.employee.lastName }}
        </a-select-option>
      </a-select>
    </div>

    <a-divider class="mb-2 mt-0"></a-divider>

    <a-col class="mt-5" v-if="!loadedChat" :span="16" :offset="11">
      <a-spin size="large" />
    </a-col>
    <vue-advanced-chat
      v-if="currentUserId != '0' && loadedChat"
      height="calc(90vh - 120px)"
      class="mr-10"
      :current-user-id="currentUserId"
      :rooms="JSON.stringify(rooms)"
      :text-messages="JSON.stringify(textMessages)"
      show-reaction-emojis="false"
      show-audio="false"
      show-add-room="false"
      :messages="JSON.stringify(messages)"
      :message-actions="JSON.stringify(messageActions)"
      :messages-loaded="messagesLoaded"
      :rooms-loaded="roomsLoaded"
      @fetch-messages="fetchMessages($event.detail[0])"
      @open-file="openFile($event.detail[0])"
      load-first-room="true"
      show-new-messages-divider="false"
      show-footer="false"
    >
    </vue-advanced-chat>
  </div>
</template>

<script>
/* eslint-disable */

import { register } from "vue-advanced-chat";
import apiClient from "@/services/axios";
import { mapState } from "vuex";

import moment from "moment";

register();
export default {
  computed: {
    ...mapState(["settings", "user"]),
  },
  components: {},
  data() {
    return {
      loadedChat: true,
      teachers: [],
      roomsLoaded: false,
      currentUserId: "0",
      rooms: [],
      messages: [],
      messagesLoaded: false,
      selectedRoom: null,
      unseenMessages: [],
      messageActions: [],
      textMessages: {
        ROOMS_EMPTY: this.$t("chat.ROOMS_EMPTY"),
        ROOM_EMPTY: this.$t("chat.ROOM_EMPTY"),
        NEW_MESSAGES: this.$t("chat.NEW_MESSAGES"),
        MESSAGE_DELETED: this.$t("chat.MESSAGE_DELETED"),
        MESSAGES_EMPTY: this.$t("chat.MESSAGES_EMPTY"),
        CONVERSATION_STARTED: this.$t("chat.CONVERSATION_STARTED"),
        TYPE_MESSAGE: this.$t("chat.TYPE_MESSAGE"),
        SEARCH: this.$t("chat.SEARCH"),
        IS_ONLINE: this.$t("chat.IS_ONLINE"),
        LAST_SEEN: this.$t("chat.LAST_SEEN"),
      },
    };
  },

  async created() {
    await apiClient
      .post("/teachers/filter", {
        query: { status: "active" },
      })
      .then((res) => (this.teachers = res.data));
  },

  methods: {
    async fetchAndUpdateRooms() {
      let unseenMessages = [];
      await apiClient.get("/pt-chat/teacher/unseenMessages").then((res) => {
        unseenMessages = res.data;
      });

      const rooms = [];
      let update = false;

      // get all parent chats
      await apiClient
        .get(`/pt-chat/teacher/${this.currentUserId}/parentChats`)
        .then((res) => {
          res.data.map((parent) => {
            const name = `${parent.father.firstName} ${parent.father.lastName} / ${parent.mother.firstName} ${parent.mother.lastName}`;
            const photo = parent.photo
              ? this.settings.base_url +
                "/" +
                parent.photo.replaceAll("\\", "/")
              : "-";

            const lastMessage = {
              _id: parent.lastMessage._id,
              content: parent.lastMessage.message,
              senderId:
                parent.lastMessage.sentBy == "parent"
                  ? parent.lastMessage.parentId
                  : this.currentUserId,
              username: name,
              saved: true,
              timestamp: new Date(parent.lastMessage.createdAt)
                .toString()
                .substring(16, 21),
              seen: parent.lastMessage.seen,
            };
            if (!this.rooms.find((room) => room.roomId == parent._id)) {
              update = true;
              rooms.push({
                roomId: parent._id,
                roomName: name,
                unreadCount:
                  unseenMessages.find((p) => p._id == parent._id)?.count || 0,
                avatar: photo,
                lastMessage,
                users: [
                  {
                    _id: this.currentUserId,
                    username: "Teacher",
                  },
                  {
                    _id: parent._id,
                    username: name,
                  },
                ],
              });
            }
          });

          this.rooms.push(...rooms);
          if (this.rooms.length > 0 && !update) {
            this.selectedRoom = this.rooms[0];
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.roomsLoaded = true;
        });
    },
    roomNewMessages(roomId, count) {
      const rooms = [...this.rooms];
      rooms.map((room) => {
        if (room.roomId == roomId) {
          room.unreadCount += count;
        }
        return room;
      });
      this.rooms = rooms;
    },
    fetchMessages({ room, options = {} }) {
      this.selectedRoom = room;
      const messages = [];

      this.messagesLoaded = false;

      room.unreadCount = 0;
      this.rooms.map((m) => {
        if (m.roomId == room.roomId) {
          m.unreadCount = 0;
        }
        return m;
      });

      // get messages from db of selected room(parent id)
      apiClient
        .get(`/pt-chat/teacher/${this.currentUserId}/messages/${room.roomId}`)
        .then((res) => {
          res.data.map((msg) => {
            const message_files = [];
            if (msg.files && msg.files.length > 0) {
              msg.files.map((file) => {
                const splited = file.split(".");
                const splitedName = splited[splited.length - 2].split("--")[1];
                message_files.push({
                  name: splitedName,
                  type: splited[splited.length - 1],
                  url:
                    this.settings.base_url + "/" + file.replaceAll("\\", "/"),
                  preview:
                    this.settings.base_url + "/" + file.replaceAll("\\", "/"),
                });
              });
            }

            messages.push({
              _id: msg.createdAt,
              messageId: msg._id,
              content: `(${this.$t("chat.deleted")}) ${msg.message}`,
              senderId:
                msg.sentBy == "teacher" ? this.currentUserId : msg.parent,
              timestamp: new Date(msg.createdAt).toString().substring(16, 21),
              date: moment(msg.createdAt).format("DD/MM/YYYY"),
              files: message_files,
              seen: msg.seen,
              saved: true,
              deleted: false,
            });
          });

          setTimeout(() => {
            this.messagesLoaded = true;
            this.messages = messages;
          }, 100);
        })
        .catch((err) => {
          console.error(err);
        });
    },

    openFile({ file }) {
      if (file.action == "download") window.open(file.file.url, "_blank");
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    async selectTeacher(val) {
      this.loadedChat = false;
      this.messages = [];
      this.rooms = [];
      this.currentUserId = val;
      await this.fetchAndUpdateRooms();
      this.loadedChat = true;
    },
  },
};
</script>

<style lang="scss">
body {
  font-family: "Quicksand", sans-serif;
}
</style>
